body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button.dot {
  background: transparent;
  outline: none;
  border: none;
  padding-left: 25px;
  position: relative;
  cursor: pointer;
  font-size: 18px;
  margin-bottom: 15px;
}

button.dot:before {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: #ddd;
}

button.dot:hover:before {
  background-color: #ccc;
}
